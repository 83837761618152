@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
* {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
}

/* Navbar Active Handler */
a.active {
  color: #fff;
}

/* Pagination active Handler */
ul.pagination li.active {
  color: #fff;
  font-weight: bold;
  background-color: gray;
}

ul.pagination {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  padding: 1rem 0;
}

ul.pagination li {
  border: 1px solid #888;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 14px;
}

ul.pagination li:first-child {
  border: transparent;
  width: fit-content;
  padding: 0px 8px;
}

ul.pagination li:last-child {
  border: transparent;
  width: fit-content;
  padding: 0px 8px;
}

ul.pagination li:last-child::after {
  content: "››";
}

ul.pagination li:first-child::before {
  content: "‹‹";
}

/* checkcontainer Height */
#container {
  min-height: calc(100vh - 95px);
}

/* Radio in Add Customer Form */
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  width: fit-content;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
  transform: translate(-50%,-50%);
	border-radius: 50%;
	background: white;
}